<template>
  <transition name="zoom-fade" mode="out-in">
    <b-card no-body style="padding: 0; margin: 0">
      <b-card-body class="mb-2" style="padding: 0">
        <b-form @submit.prevent="submit" class="data-form mt-3">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Дата от"
                  rules="required"
                >
                  <span class="text-muted" style="font-size: 12px"
                    >Начиная с</span
                  >
                  <cleave
                    v-model="Statistics.filters.period.start"
                    class="form-control"
                    :raw="false"
                    :options="date"
                    placeholder="ДД\ММ\ГГГГ"
                  />
                  <small class="text-danger font-small-1">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Дата до"
                  rules="required"
                >
                  <span class="text-muted" style="font-size: 12px"
                    >Заканчивая</span
                  >
                  <cleave
                    v-model="Statistics.filters.period.end"
                    class="form-control"
                    :raw="false"
                    :options="date"
                    placeholder="ДД\ММ\ГГГГ"
                  />
                  <small class="text-danger font-small-1">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            @click="submit"
            variant="primary"
            block
            style="height: 56px; font-size: 16px; border-radius: 8px"
            >Применить</b-button
          >
        </b-form>
      </b-card-body>
    </b-card>
  </transition>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Cleave from 'vue-cleave-component';
import { required } from '@validations';

export default {
  data() {
    return {
      required,
      date: {
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y'],
      },
    };
  },
  props: {
    Statistics: Object,
  },
  methods: {
    submit() {
      this.$emit('changePeriod');
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
};
</script>

<style></style>
