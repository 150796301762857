<template>
  <b-card no-body class="member-card justify-content-between" style="padding: 20px">
    <div class="d-flex justify-content-between" :style="{
      marginBottom: short ? '0px' : '12px'
    }">
      <div class="d-flex justify-content-between">
        <img
          alt="avatar"
          style="width: 60px; height: 60px; margin-right: 16px"
          :src="require('@/assets/images/pages/team/avatars/' +member.rank+ '.png')"
        />
        <div class="d-flex flex-column">
          <span class="small-title text-muted">Исполнитель</span>
          <span class="member-name" style="margin-bottom: 4px">{{member.first_name}} {{member.last_name}}</span>
          <div v-if="member.rank == 3" class="badge-level">
            <img alt="avatar" src="@/assets/images/pages/team/icons/lvl_3.svg" />
            <span>партнер</span>
          </div>
          <div v-else-if="member.rank == 2" class="badge-level">
            <img alt="avatar" src="@/assets/images/pages/team/icons/lvl_2.svg" />
            <span>профи</span>
          </div>
          <div v-else class="badge-level">
            <img alt="avatar" src="@/assets/images/pages/team/icons/lvl_1.svg" />
            <span>новичек</span>
          </div>
        </div>
      </div>
      <div v-if="!short">
        <div class="d-flex">
          <feather-icon v-if="!editable" @click="editable = true" icon="EditIcon" class="text-muted" style="cursor: pointer" size="18" color="gray" />
          <feather-icon v-if="editable" @click="editable = false" icon="CheckSquareIcon" class="text-muted" style="cursor: pointer" size="18" color="gray" />
          <feather-icon v-if="editable" @click="editable = false" icon="XIcon" class="text-muted" style="cursor: pointer" size="18" color="gray" />
        </div>
      </div>
    </div>
    <div v-if="!short">
      <div class="separator"></div>
      <div class="d-flex flex-column">
        <span class="normal-title text-muted">Заработано</span>
        <span class="normal-text font-weight-500">{{member.income.formatMoney('','','')}} ₽</span>
      </div>
      <div class="separator"></div>
      <div class="d-flex flex-column">
        <span class="normal-title text-muted">Выполнено заявок</span>
        <span class="normal-text font-weight-500">{{member.today_offers}} шт</span>
      </div>
      <div class="separator"></div>
      <div v-if="!editable">
        <div class="d-flex justify-content-between" style="margin-bottom: 8px">
          <span class="normal-title text-muted">{{member.plan}} {{Utils.declOfNum(member.plan, ["заявка", "заявки", "заявок"])}} в день</span>
          <span class="normal-title text-muted">{{parseInt(member.today_offers * 100 / member.plan)}} %</span>
        </div>
        <div class="d-flex bonus-progress" style="margin-bottom: 12px">
          <b-progress :max="member.plan" height="8px" style="width: 100%">
            <b-progress-bar :value="member.today_offers"></b-progress-bar>
          </b-progress>
        </div>
        <b-link @click="editable = true"> Редактировать план </b-link>
      </div>
      <div v-else>
        <div class="d-flex justify-content-between" style="margin-bottom: 8px">
          <span class="normal-title text-muted">Текущий план</span>
          <span class="normal-title text-muted">{{member.plan}} {{Utils.declOfNum(member.plan, ["заявка", "заявки", "заявок"])}} в день</span>
        </div>
        <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 8px">
          <span class="normal-title text-muted">Новый план</span>
          <div class="d-flex align-items-center">
            <b-form-input
              id="new-plan"
              placeholder=""
              v-model="new_plan"
              style="width: 50px; height: 27px; margin-right: 4px; padding: 0.438rem; text-align: center;"
            />
            <span class="normal-title text-muted">заявок в день</span>
          </div>
        </div>
      </div>
      <div class="separator" style="margin-bottom: 20px"></div>
      <b-button v-if="!editable" variant="outline-primary" block style="border-radius: 8px">
        Статистика
      </b-button>
      <b-button v-else variant="danger" block style="border-radius: 8px">
        Исключить из команды
      </b-button>
    </div>
  </b-card>
</template>

<script>
import Utils from "@/modules/utils/";

export default {
  data() {
    return {
      Utils,
      editable: false,
      new_plan: ''
    }
  },
  methods: {
    changePlan() {

    }
  },
  props: {
    member: Object,
    short: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.font-weight-500 {
  font-weight: 500;
}
.member-card {
  border: 1px solid #ebecf2;
  .separator {
    margin: 8px 0;
  }
}
.bonus-progress {
  .progress-bar {
    background: linear-gradient(249.98deg, #fbba36 -47.44%, #edbf64 149.19%);
  }
}
.small-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.06px;
}
.normal-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.normal-text {
  font-size: 20px;
  line-height: 24px;
}
.member-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.badge-level {
  width: fit-content;
  background: #ffefd1;
  border-radius: 32px;
  img {
    padding: 3px 5px;
  }
  span {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    padding: 3px 8px 3px 0;
  }
}
</style>
