<template>
  <div>
    <p class="main-header-section">Команда</p>
    
    <div class="d-flex">
      <div style="width:340px;">
        <div class="d-flex flex-column" style="position: sticky; top: 22px;">
          <short-team-card />
          <finance-info-card />
          <my-status-card />
        </div>
      </div>

      <div style="width: calc(100% - 340px); padding-left: 20px">
        <preview-content-card style="margin-bottom: 56px" />
        <main-content-card style="margin-bottom: 56px" />
        <advantage-info-card style="margin-bottom: 56px" />
        <faq-view />
      </div>
    </div>

  </div>
</template>

<script>
import ShortTeamCard from "../widgets/ShortTeamCard"
import FinanceInfoCard from "../widgets/FinanceInfoCard"

import PreviewContentCard from "../widgets/PreviewContentCard"
import MainContentCard from "../widgets/MainContentCard"
import MyStatusCard from "../widgets/MyStatusCard"
import AdvantageInfoCard from "../widgets/AdvantageCard"
import FaqView from "../widgets/Faq"

export default {
  data() {
    return {

    }
  },
  components: {
    ShortTeamCard,
    FinanceInfoCard,
    PreviewContentCard,
    MainContentCard,
    MyStatusCard,
    AdvantageInfoCard,
    FaqView
  }
};
</script>

