<template>
  <div>
    <b-card>
      <b-card-title style="margin-bottom: 4px">
        Команда Ивана Ивановича
      </b-card-title>
      <b-card-text class="text-muted" style="margin-bottom: 8px">
        Участников: 0
      </b-card-text>
      <div class="badge-empty-team">
        <img
          alt="warning"
          style="margin-right: 6px"
          src="@/assets/images/pages/team/icons/warning_status.svg"
        />
        Вы пока не добавили никого в команду
      </div>

      <b-button
        variant="success"
        block
        @click="modalsVisibility.share = true"
        style="border-radius: 8px"
      >
        Пригласить в команду
      </b-button>
    </b-card>

    <b-modal
      id="share-container"
      v-model="modalsVisibility.share"
      centered
      title=""
      hide-footer
      modal-class=""
    >
      <share-modal-content @close="closeModal" />
    </b-modal>
  </div>
</template>

<script>
import ShareModalContent from "../modals/share";

export default {
  data() {
    return {
      modalsVisibility: {
        share: true,
      },
    };
  },
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
  },
  components: {
    ShareModalContent,
  },
};
</script>

<style lang="scss">
.badge-empty-team {
  margin-bottom: 20px;
  display: flex;
  padding: 4px 8px;
  border-radius: 50px;
  background-color: #ffefd1;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.dark-layout {
  .badge-empty-team {
    background-color: #ffefd133;
  }
}
</style>
