<template>
  <b-card no-body style="padding: 24px 0px" class="statisctics-table">
    <b-skeleton-table
      v-if="Statistics.is_loading"
      animation="fade"
      :columns="7"
      :rows="5"
    >
    </b-skeleton-table>

    <b-table-simple v-else>
      <b-thead>
        <b-tr>
          <b-th>Участник</b-th>
          <b-th>Роль</b-th>
          <b-th>Статус</b-th>
          <b-th>Заявки</b-th>
          <b-th>Сделки</b-th>
          <b-th>AR</b-th>
          <b-th>Доход</b-th>
          <b-th
            >Выполнение<br />
            плана</b-th
          >
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <div
          v-for="(item, i) in Statistics.items"
          :key="i"
          v-ripple
          class="font-weight-normal cursor-pointer"
          style="display: contents"
        >
          <b-tr @click="toggleCollapse(item)">
            <b-td class="font-weight-normal">
              {{ item.offer.title }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.clicks }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.clicks }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.uniq_clicks }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.ecpc.formatMoney(2, "", "", ".") }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.applications }}
            </b-td>
            <b-td class="font-weight-normal text-nowrap">
              {{ parseFloat(item.ar).formatMoney(2, "", "", ".") }} %
            </b-td>
            <b-td class="font-weight-normal text-nowrap">
              {{ item.income.formatMoney("", "", "") + " ₽" }}
            </b-td>
            <b-td class="font-weight-normal">
              <div class="outline-block">
                <feather-icon
                  v-if="item.collapsed"
                  size="22"
                  icon="ChevronUpIcon"
                />
                <feather-icon
                  v-else
                  class="text-muted"
                  size="22"
                  icon="ChevronDownIcon"
                />
              </div>
            </b-td>
          </b-tr>

          <!-- collapsed -->
          <b-tr>
            <b-td colspan="14" style="padding: 0; border-top: 0px">
              <b-collapse v-model="item.collapsed" style="margin-right: 2rem">
                <div>
                  <b-tabs pills>
                    <b-tab title="Заявки">
                      <b-card no-body>
                        <graphic-orders-view
                          :item="item"
                          v-if="item.collapsed"
                        />
                      </b-card>
                    </b-tab>
                    <b-tab title="Баланс">
                      <b-card no-body>
                        <graphic-balance-view
                          :item="item"
                          v-if="item.collapsed"
                        />
                      </b-card>
                    </b-tab>
                    <b-tab title="AR">
                      <b-card no-body>
                        <graphic-ar-view :item="item" v-if="item.collapsed" />
                      </b-card>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-collapse>
            </b-td>
          </b-tr>
          <!-- !collapsed -->
        </div>
      </b-tbody>
    </b-table-simple>
    <template v-if="!Statistics.is_loading && Statistics.items.length === 0">
      <div
        class="d-flex flex-column justify-content-center"
        style="padding: 24px"
      >
        <div class="nodata-icon ml-auto mr-auto"></div>
        <p class="text-muted mt-1 ml-auto mr-auto text-center">
          Нет данных для отображения
        </p>
      </div>
    </template>

    <b-card-body
      v-if="Statistics.items.length > 0"
      class="d-flex justify-content-between pt-0 pb-0 bottom-table"
    >
      <span class="font-weight-bolder">
        Всего: {{ Statistics.total_count }}
      </span>
      <b-pagination-nav
        :value="Statistics.current_page"
        :link-gen="linkGen"
        :number-of-pages="Statistics.last_page"
        use-router
      ></b-pagination-nav>
    </b-card-body>
  </b-card>
</template>

<script>
import GraphicOrdersView from "../charts/statistics-orders-graph";
import GraphicBalanceView from "../charts/statistics-balance-graph";
import GraphicArView from "../charts/statistics-ar-graph";

export default {
  data() {
    return {
      fields: [
        { key: "id", label: "#" },
        { key: "title", label: "TITLE" },
        { key: "description", label: "DESC" },
        { key: "url", label: "URL" },
        { key: "toggle", label: "" },
      ],
    };
  },
  props: {
    Statistics: Object,
  },
  methods: {
    linkGen(pageNum) {},
    toggleCollapse(item) {
      if (item.collapsed === null || typeof item.collapsed === "undefined") {
        this.$set(item, "collapsed", true);
      } else {
        this.$set(item, "collapsed", !item.collapsed);
      }
    },
  },
  components: {
    GraphicOrdersView,
    GraphicArView,
    GraphicBalanceView,
  },
};
</script>

<style lang="scss">
.statisctics-table {
  .nav-pills {
    padding-left: 2rem;
  }
  th {
    border-top: none !important;
  }
  tbody {
    div:last-child {
      td {
        border-bottom: none !important;
      }
    }
    td {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1e2041;
      @media screen and (max-width: 1500px) {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  thead {
    th {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      text-transform: inherit;

      @media screen and (max-width: 1500px) {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .bottom-table {
      padding: 0.5rem;
    }
  }
}
</style>
