<template>
  <div class="statistics">
    <filters-view />

    <table-view :Statistics="Statistics" />
  </div>
</template>

<script>
// import Offers from "@/modules/offers/"
import Statistics from '@/modules/contents/statistics';

import tableView from './tables/desktop';
import FiltersView from './widgets/filters';

export default {
  data() {
    return {
      // Offers,
      Statistics,
    };
  },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {},
    changeActiveCategories(categories) {},
  },
  components: {
    FiltersView,
    tableView,
  },

  computed: {},

  watch: {},
  mounted() {
    Statistics.list();
  },
};
</script>

<style lang="scss"></style>
