<template>
  <b-card no-body>
    <b-overlay
      :show="!is_can_show_graphic"
      blur="50"
      variant="transparent"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
      style="height: 400px"
    >
      <vue-apex-charts
        v-if="is_can_show_graphic"
        type="bar"
        height="400px"
        width="100%"
        :options="chartOptions"
        :series="chartData"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import Charts from '@/modules/charts';
import Statistics from '@/modules/contents/statistics';

import { $themeColors } from '@themeConfig';
import VueApexCharts from 'vue-apexcharts';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
  data() {
    return {
      Charts,
      Statistics,
      chartData: [{}, {}],
      chartDataRaw: [],
      skin: useAppConfig().skin,
      is_can_show_graphic: true,
      series: [
        {
          name: 'Клики',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 33, 44, 10],
        },
      ],
      chartOptions: {
        chart: {
          animations: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: false,
          },
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 3,
            columnWidth: '60%',
            borderRadiusOnAllStackedSeries: true,
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
            },
          },
        },
        grid: {
          show: true,
          borderColor: '#EBECF2',
          column: {
            colors: ['#FFF'],
            opacity: 0.1,
          },
          row: {
            colors: ['#EBECF2'],
            opacity: 0.1,
          },
          padding: {
            left: 10,
            right: 0,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        theme: {
          monochrome: {
            enabled: false,
            // color: $themeColors.success,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
        colors: ['#7E76E3', '#EBE6FD'],
        xaxis: {
          // categories: Charts["orders"].labels,
          categories: [
            '00:00',
            '02:00',
            '04:00',
            '06:00',
            '08:00',
            '10:00',
            '12:00',
            '14:00',
            '16:00',
            '18:00',
            '20:00',
            '22:00',
          ],
          type: 'category',
          labels: {
            show: true,
            rotate: -90,
            // trim: true,
            style: {
              fontSize: '12px',
              colors:
                useAppConfig().skin.value === 'light' ? '#A3AEC4' : '#FFF',
            },
            // formatter: (value) => {
            //   if (!value) return "";
            //   var date = new Date(value * 1000);
            //   // var day = new Intl.DateTimeFormat('ru', {  day: 'numeric' }).format(date);
            //   var formatted = new Intl.DateTimeFormat("ru", {
            //     day: "numeric",
            //     year: "2-digit",
            //     month: "numeric",
            //   }).format(date);

            //   return formatted;
            // },
            // format: "dd/MM",
          },
        },
        yaxis: {
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: {
              colors:
                useAppConfig().skin.value === 'light' ? '#A3AEC4' : '#FFF',
            },
            formatter: (val) => { return val},
          },
          title: {
            text: 'Заработано, ₽',
            rotate: -90,
            offsetX: 60,
            offsetY: -214,
            style: {
              color: '#A3AEC4',
              fontSize: '14px',
              // fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'y-title-position',
            },
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
        legend: {
          offsetY: 15,
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '16px',
          // fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          markers: {
            width: 20,
            height: 20,
            radius: 100,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -2,
            offsetY: 5,
          },
          itemMargin: {
            horizontal: 20,
            vertical: 10,
          },
        },
      },
    };
  },
  methods: {
    updateChart() {
      let chartData_1 = [];
      let chartData_2 = [];
      let labels = [];

      this.chartDataRaw.forEach((data) => {
        chartData_1.push(parseInt(data.y_1));
        chartData_2.push(parseInt(data.y_2));
        labels.push(data.x);
      });

      this.chartData = [
        { name: 'Заработок', data: chartData_1 },
        { name: 'eCPC', data: chartData_2 },
      ];

      this.chartOptions.xaxis.categories = labels;

      setTimeout(() => {
        this.is_can_show_graphic = true;
      }, 155);
    },
    loadGraph() {
      this.is_loading = true;
      this.is_can_show_graphic = false;

      this.$request
        .get('statistics.getBalanceGraphic', {
          for: 'clicks',
          offer: this.item.offer.id,
          w_utm1: this.item.w_utm1,
          w_utm2: this.item.w_utm2,
          select: Statistics.filters.select,
          period: Statistics.filters.period,
        })
        .then((result) => {
          this.chartDataRaw = result;
          this.updateChart();
          this.is_loading = false;
        });
    },
  },
  props: {
    item: Object,
  },
  computed: {},

  components: {
    VueApexCharts,
  },
  watch: {},
  mounted() {
    this.loadGraph();
  },
};
</script>
