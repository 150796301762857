<template>
  <div class="date-filters-statistics">
    <div class="d-flex justify-content-between align-items-baseline">
      <div class="date-filters mb-2">
        <div
          class="date-filters__item"
          v-for="(val, index) in filters_date"
          :key="index"
          :class="{ active: val.indicator == Statistics.filters.select }"
          @click="select(val)"
        >
          {{ val.title }}
        </div>
      </div>
      <div class="download-btn">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        Сохранить отчет
      </div>
    </div>

    <b-card-title>
      <validation-observer
        ref="filter"
        class="slider-hover-prevent v-select-filter-form"
      >
        <showAt breakpoint="small">
          <div class="d-flex flex-column align-items-center gap-8">
            <div class="w-100">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between foc">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Offers.categories"
                        v-model="filters.product"
                        label="name"
                        :clearable="true"
                        placeholder="Продукт"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="w-100">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Statistics.utms.w_utm1"
                        v-model="filters.w_utm1"
                        label="w_utm1"
                        :clearable="true"
                        placeholder="Выберите метку 1-го уровня"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="w-100">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Statistics.utms.w_utm2"
                        v-model="filters.w_utm2"
                        label="w_utm2"
                        :clearable="true"
                        placeholder="Выберите метку 2-го уровня"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="w-100">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Offers.companies"
                        v-model="filters.company"
                        label="name"
                        :clearable="true"
                        placeholder="Выберите партнера"
                        outlined
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="w-100">
              <div
                class="d-flex justify-content-center clear-filter"
                @click="clear"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="18"
                  style="margin-right: 6px"
                />
                <span> Сбросить фильтры </span>
              </div>
            </div>
          </div>
        </showAt>
        <showAt breakpoint="medium">
          <b-row class="align-items-center">
            <b-col md="6">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between foc">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Offers.categories"
                        v-model="filters.product"
                        label="name"
                        :clearable="true"
                        placeholder="Выберите участника"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Statistics.utms.w_utm1"
                        v-model="filters.w_utm1"
                        label="w_utm1"
                        :clearable="true"
                        placeholder="Выберите роль"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Statistics.utms.w_utm2"
                        v-model="filters.w_utm2"
                        label="w_utm2"
                        :clearable="true"
                        placeholder="Выберите статус"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </showAt>
        <showAt breakpoint="large">
          <b-row class="align-items-center">
            <b-col >
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between foc">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Offers.categories"
                        v-model="filters.product"
                        label="name"
                        :clearable="true"
                        placeholder="Выберите участника"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col >
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Statistics.utms.w_utm1"
                        v-model="filters.w_utm1"
                        label="w_utm1"
                        :clearable="true"
                        placeholder="Выберите роль"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Statistics.utms.w_utm2"
                        v-model="filters.w_utm2"
                        label="w_utm2"
                        :clearable="true"
                        placeholder="Выберите статус"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </showAt>
      </validation-observer>
    </b-card-title>

    <b-modal
      id="fiter-date-modal"
      v-model="modalsVisibility.date"
      centered
      title="Выбор даты"
      hide-footer
    >
      <date-modal-content
        @changePeriod="changePeriod"
        :Statistics="Statistics"
      />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';

import Offers from '@/modules/offers';
import Statistics from '@/modules/contents/statistics';

import dateModalContent from '../modals/date';

export default {
  data() {
    return {
      Offers,
      Statistics,
      modalsVisibility: {
        date: false,
      },
      filters: {
        product: null,
        w_utm1: null,
        w_utm2: null,
        company: null,
      },
      filters_date: [
        {
          indicator: 'today',
          title: 'Сегодня',
        },
        {
          indicator: 'yesterday',
          title: 'Вчера',
        },
        {
          indicator: 'week',
          title: 'За неделю',
        },
        {
          indicator: 'month',
          title: 'За месяц',
        },
        {
          indicator: 'all',
          title: 'За все время',
        },
        {
          indicator: 'period',
          title: 'Свой период',
        },
      ],
    };
  },
  methods: {
    changePeriod() {
      Statistics.list();
      this.modalsVisibility.date = false;
    },
    select(val) {
      Statistics.filters.select = val.indicator;
      if (val.indicator == 'period') {
        this.modalsVisibility.date = true;
      } else {
        Statistics.list();
      }
    },
    clear() {
      if (
        this.filters.product ||
        this.filters.company ||
        this.filters.w_utm1 ||
        this.filters.w_utm2
      ) {
        this.filters = {
          product: null,
          company: null,
          w_utm1: null,
          w_utm2: null,
        };
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(bef) {
        Statistics.filters.product = this.filters.product;
        Statistics.filters.company = this.filters.company;
        Statistics.filters.w_utm1 = this.filters.w_utm1;
        Statistics.filters.w_utm2 = this.filters.w_utm2;
        Statistics.list();
      },
    },
  },
  mounted() {
    if (Offers.categories.length == 0) Offers.getCategories();
    if (Offers.companies.length == 0) Offers.getCompanies();
    if (
      Statistics.utms.w_utm1.length == 0 ||
      Statistics.utms.w_utm2.length == 0
    )
      Statistics.getUtm();
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    dateModalContent,
  },
};
</script>

<style lang="scss" scoped>
.date-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  &__item {
    cursor: pointer;
    background: transparent;
    border: 1px solid var(--link-primary);
    border-radius: 60px;
    padding: 6px 14px;
    color: var(--link-primary);
  }
  &__item.active {
    color: #fff;
    background-color: var(--link-primary);
  }
}
.clear-filter {
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
}
.date-filters-statistics {
  @media screen and (max-width: 1128px) {
    .vs__search,
    .vs__search:focus {
      font-size: 16px;
    }

    .col-md-6 {
      padding-bottom: 8px;
    }

    .form-group {
      padding: 4px 20px !important;
      margin: 0 !important;
    }
  }
}
.v-select-filter-form .form-group-with-label {
  border: 1px solid #EBECF2;
}
</style>
