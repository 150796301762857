<template>
  <div>
    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant="transparent"
      style="flex-wrap: wrap; position: unset !important"
    >
      <b-card-body
        v-if="items.length === 0 && !is_loading"
        class="d-flex flex-column p-4"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="nodata-icon ml-auto mr-auto"></div>
          <p class="text-muted mt-1 ml-auto mr-auto text-center">
            Нет предложений
          </p>
        </div>
      </b-card-body>

      <div class="d-grid-cards-catalog" v-else-if="!is_loading">
        <member-card-view
          v-for="(member, index) in items"
          :key="index"
          :member="member"
          :short="false"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import MemberCardView from "../../../components/MemberCard";

export default {
  data() {
    return {
      is_loading: false,
      items: [
        {
          id: 1,
          first_name: "Василий",
          last_name: "Пупкин",
          today_offers: 2,
          plan: 4,
          income: 10000,
          rank: 1,
        },
        {
          id: 2,
          first_name: "Денис",
          last_name: "Лохов",
          today_offers: 2,
          plan: 8,
          income: 2500,
          rank: 2,
        },
        {
          id: 3,
          first_name: "Артем",
          last_name: "Сидоров",
          today_offers: 0,
          plan: 2,
          income: 0,
          rank: 1,
        },
        {
          id: 4,
          first_name: "Иван",
          last_name: "Иванов",
          today_offers: 1,
          plan: 3,
          income: 3000,
          rank: 3,
        },
      ],
    };
  },
  components: {
    MemberCardView,
  },
};
</script>

<style></style>
