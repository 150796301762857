<template>
  <div>
    <b-card-title style="width: 88%; font-size: 16px">
      Если человек не является пользователем 2work, то отправьте ему эту ссылку
    </b-card-title>

    <div
      class="d-flex justify-content-between mb-2 share-block-card"
    >
      <span
        >Заработок с каждого приведенного<br />
        пользователя</span
      >
      <div class="d-flex align-items-center">
        <b-card-title class="mr-1" style="margin: 0px"> 5% </b-card-title>
        <span
          >от тарифа<br />
          обучения</span
        >
      </div>
    </div>

    <div class="d-flex justify-content-between mb-2">
      <div
        class="d-flex justify-content-between align-items-center"
        style="padding: 12px; border: 1px solid #ebecf2; border-radius: 8px"
      >
        <span class="mr-1 text-muted">{{link}}</span>
        <feather-icon
          icon="CopyIcon"
          class="text-muted"
          style="cursor: pointer"
          @click="copyUrl"
        />
        <div ref="container"></div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-around">
          <div
            style="width: 34px;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #EFF1F4;
              border-radius: 50%;
              cursor: pointer;
          }"
            v-for="(soc, key) in socials"
            :key="key"
          >
            <img
              alt=""
              class="network_icon"
              :src="
                require('@/assets/images/site/icons/networks_icons/primary/' +
                  key +
                  '.svg')"
            />
          </div>
        </div>
        <span class="fs-12 text-muted">Поделиться в соц. сетях</span>
      </div>
    </div>

    <b-card-title style="font-size: 16px">
      Если человек уже зарегистрирован, то введите его ID, <br />чтобы оправить
      запрос на добавление в команду
    </b-card-title>

    <div
      class="d-flex justify-content-between mb-2 share-block-card"
    >
      <span
        >Заработок с каждого приведенного<br />
        пользователя</span
      >
      <div class="d-flex align-items-center">
        <b-card-title style="margin: 0px"> 10 </b-card-title>
        <div
          class="currency-icon"
          style="
            background-size: 18px 18px;
            width: 18px;
            height: 18px;
            margin-left: 4px;
          "
        ></div>
      </div>
    </div>

    <div class="d-flex align-items-center" style="margin-bottom: 8px">
      <b-form-group
        class="form-group-with-label mr-1"
        style="border-radius: 8px; padding: 16px; margin-bottom: 0px; flex: 1"
      >
        <validation-provider rules="integer">
          <b-form-input
            id="member_id"
            v-model="member_id"
            placeholder="ID пользователя"
            style="font-size: 14px; font-weight: 400"
            v-mask="'#######################'"
          />
        </validation-provider>
      </b-form-group>
      <b-button
        variant="primary"
        block
        style="padding: 18px 16px; border-radius: 8px; width: 200px"
      >
        Отправить запрос
      </b-button>
    </div>

    <span
      class="user-select-none"
      style="text-decoration: underline;"
      v-b-popover.hover.top="
        `ID присваевается каждому пользователю и отображается в личном кабинете`"
      >Где взять ID?</span
    >
  </div>
</template>

<script>
import { required, integer } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      required,
      integer,
      link: 'cpacer/hbhbkvbdsbli/sdwjvadvno',
      member_id: "",
      socials: {
        vk: "",
        telegram: "",
        whatsapp: "",
      },
    };
  },
  methods: {
    copyUrl( args ) {            
      let container = this.$refs.container;
      this.$copyText( this.link, container );
      
      this.$toast({
          component: ToastificationContent,
          props: {
              title: this.$t('toasts.titles.copy'),
            text: 'Ссылка успешно скопирована в буфер обмена.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          }
      });  
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style lang="scss">
.share-block-card {
  background: #eff1f4;
  border-radius: 16px; 
  padding: 12px
}
.dark-layout {
  .share-block-card {
    background: #eff1f40f;
  }
}
</style>
