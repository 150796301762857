<template>
  <div class="faq">
    <p class="faq-title" data-anime="animate__fadeIn">Ответы на вопросы</p>

    <b-card no-body style="padding: 24px 32px">
      <div class="q-section-block bg-white-color faq-block">
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="200"
        >
          <div class="faq-section-header" v-b-toggle.faq-1>
            <p>Какие роли и статусы существуют в команде?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-1" class="faq-section-content" role="tabpanel">
            <p>
              Попадая в команду, участник становится Исполнителем со статусом Новичок. Чтобы повысить статус в команде, нужно сделать более 200 заявок после того, как пользователь попал в команду. Также при статусе Профи, выполнении более 1000 заявок и уровне рейтинга от Профессионала и выше, пользователь может быть назначен как менеджер команды – он будет получать процент за управление Исполнителями и получает право следить за статистикой, ставить план и управлять участниками.
            </p>
          </b-collapse>
        </div>
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="300"
        >
          <div class="faq-section-header" v-b-toggle.faq-2>
            <p>Можно ли состоять в нескольких командах одновременно?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-2" class="faq-section-content" role="tabpanel">
            <p>???</p>
          </b-collapse>
        </div>
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="400"
        >
          <div class="faq-section-header" v-b-toggle.faq-3>
            <p>Может ли пользователь самостоятельно выйти из команды?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-3" class="faq-section-content" role="tabpanel">
            <p>??</p>
          </b-collapse>
        </div>
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="400"
        >
          <div class="faq-section-header" v-b-toggle.faq-4>
            <p>Могуть ли пользователи добавлять новых участников в команду?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-4" class="faq-section-content" role="tabpanel">
            <p>??</p>
          </b-collapse>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// import alertCardView from '@/components/AlertCard';

export default {
  data() {
    return {};
  },
  components: {
    // alertCardView,
  },
};
</script>

<style lang="scss" scoped>
.faq {
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  .faq-section-header {
    @media screen and (max-width: 744px) {
      p {
        max-width: 224px;
      }
    }
  }

  .faq-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    font-style: normal;
    @media screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}
</style>
