<template>
  <b-card no-body class="my-status-card" style="padding: 20px">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between" style="margin-bottom: 8px">
        <span class="normal-title text-muted"
          >Статус</span
        >
        <span class="normal-title text-muted"
          >150 заявок до Профи</span
        >
      </div>

      <div v-if="member.rank == 3" class="badge-level">
        <img alt="avatar" src="@/assets/images/pages/team/icons/lvl_3.svg" />
        <span>партнер</span>
      </div>
      <div v-else-if="member.rank == 2" class="badge-level">
        <img alt="avatar" src="@/assets/images/pages/team/icons/lvl_2.svg" />
        <span>профи</span>
      </div>
      <div v-else class="badge-level">
        <img alt="avatar" src="@/assets/images/pages/team/icons/lvl_1.svg" />
        <span>новичек</span>
      </div>
    </div>
    <div class="separator"></div>
    <div class="d-flex flex-column">
      <span class="normal-title text-muted">Роль в команде</span>
      <span class="normal-text font-weight-500"
        >Исполнитель</span
      >
    </div>
    <div class="separator"></div>
    <div>
      <div class="d-flex justify-content-between" style="margin-bottom: 8px">
        <span class="normal-title text-muted"
          >{{ 12 }}
          {{ Utils.declOfNum(12, ["заявка", "заявки", "заявок"]) }} в
          день</span
        >
        <span class="normal-title text-muted"
          >{{ parseInt((4 * 100) / 12) }} %</span
        >
      </div>
      <div class="d-flex bonus-progress" style="margin-bottom: 12px">
        <b-progress :max="12" height="8px" style="width: 100%">
          <b-progress-bar :value="4"></b-progress-bar>
        </b-progress>
      </div>
      <div class="badge-info-content">
        <img alt="info" style="margin-right: 6px" src="@/assets/images/pages/team/icons/info_status.svg" />
        Выполните еще 8 заявок,<br> 
        чтобы выполнить план
      </div>
    </div>
    <div class="separator" style="margin-bottom: 20px"></div>
    <b-button variant="outline-primary" style="border-radius: 8px">
      Перейти к предложениям
    </b-button>
  </b-card>
</template>

<script>
import Utils from "@/modules/utils/";

export default {
  data() {
    return {
      Utils,
      member: {
        rank: 2
      }
    };
  },
  methods: {
    changePlan() {},
  },
  props: {
  },
};
</script>

<style lang="scss">
.my-status-card {
  .separator {
    margin: 8px 0;
  }
  .badge-info-content {
    background-color: #EFF1F4;
    display: flex;
    padding: 4px 8px; 
    border-radius: 50px; 
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
  .bonus-progress {
    .progress-bar {
      background: linear-gradient(249.98deg, #fbba36 -47.44%, #edbf64 149.19%);
    }
  }
  .small-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.06px;
    // color: #787E8E;
  }
  .normal-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .normal-text {
    font-size: 20px;
    line-height: 24px;
  }
  .member-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .badge-level {
    width: fit-content;
    background: #ffefd1;
    border-radius: 32px;
    img {
      padding: 3px 5px;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 3px 8px 3px 0;
    }
  }
}
.dark-layout {
  .badge-info-content {
    background-color: #eff1f414;
  }
  .badge-level {
    background-color: #ffefd133;
  }
}
</style>
