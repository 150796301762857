<template>
  <b-card class="">
    <b-tabs pills class="over-padding no-content-tab-wrapper">
      <b-tab title="Участники">
        <b-card no-body>
          <team-content-view />
        </b-card>
      </b-tab>
      <b-tab title="Статистика">
        <b-card no-body>
          <team-statistics-view />
        </b-card>
      </b-tab>
      <b-tab >
        <b-card no-body>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import TeamContentView from "../views/contents/team/desktop"
import TeamStatisticsView from "../views/contents/statistics/desktop"

export default {
  data() {
    return {
      
    }
  }, 
  components: {
    TeamContentView,
    TeamStatisticsView
  }
};
</script>

<style lang="scss">
  
</style>
