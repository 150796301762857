<template>
  <div>
    <div style="margin-bottom: 28px">
      <p class="main-header-section" style="margin-bottom: 8px">Вы зарабатываете</p>
      <span
        >от 5% с прибыли участников, а участники имеют доступ к более прибыльным
        офферам</span
      >
    </div>
    <div class="d-flex advantage-block">
      <b-card bg-variant="primary" style="margin-bottom: 0px">
        <b-card-title> Преимущества для главного в команде </b-card-title>

        <ul>
          <li>
            Вы зарабатываете с каждого приведенного пользователя на платформу и
            в вашу команду
          </li>
          <li>
            Вы можете отсматривать статистику по каждому участнику команды и
            следить за их прогрессом
          </li>
          <li>
            Вы можете сами не выполнять офферы, а набрать специалистов, которые
            будут этим заниматься
          </li>
        </ul>
      </b-card>

      <b-card bg-variant="outline-primary" style="margin-bottom: 0px">
        <b-card-title> Преимущества для участника команды </b-card-title>

        <ul>
          <li>Возможность получить скидку на обучение до 15%</li>
          <li>
            Возможность работать в команде со специалистами, обмениваться опытом
          </li>
          <li>
            Возможность открыть предложения, которые не доступны на его уровне
            рейтинга
          </li>
        </ul>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
  .advantage-block {
    justify-content: flex-start;
    flex-wrap: nowrap;
    position: unset !important;
    gap: 2%;
    flex-direction: row;
    .card {
      width: 50%;
    }
    ul {
      padding-left: 15px;
      li {
        margin-bottom: 12px;
      }
    }
  }
</style>
