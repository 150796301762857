<template>
  <b-card class="">
    <b-card-text class="text-muted">
      Заработано за добавление в команду</b-card-text
    >
    <b-card-title class="d-flex align-items-center"
      >50
      <div
        class="currency-icon"
        style="width: 18px; height: 18px; margin-left: 4px"
      ></div
    ></b-card-title>

    <b-card-text class="text-muted">Заработок с каждого участника</b-card-text>
    <b-card-title> 5%</b-card-title>

    <b-card-text class="text-muted"
      >Заработок с прибыли участников
    </b-card-text>
    <b-card-title> 200 Р</b-card-title>

    <b-button variant="primary" block style="border-radius: 8px; margin-bottom: 12px"> Вывести деньги </b-button>
    <b-button variant="outline-primary" block style="border-radius: 8px;"> Как зарабатывать больше? </b-button>
  </b-card>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.card-text {
  margin-bottom: 0px;
}
.card-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
</style>
