<template>
  <b-card>
    <div class="d-flex" style="margin-bottom: 20px">
        <div style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); order: 0; flex-grow: 0; border-radius: 24px; padding: 20px; margin-right: 20px">
            <div style="margin-bottom: 40px">
                <img alt="search" src="@/assets/images/pages/team/icons/search.svg" />
            </div>
            <span>
                Вы делитесь ссылкой на платформу 2work с человеком, которого хотите пригласить в команду
            </span>
        </div>

        <div class="d-flex" style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); order: 1; flex-grow: 1; border-radius: 24px; padding: 20px">
            <div style="padding-right: 20px; border-right: 1px solid #EFF1F4; width: 50%;">
                <div style="margin-bottom: 40px">
                    <img alt="search" src="@/assets/images/pages/team/icons/man.svg" />
                </div>
                <span>
                    Если пользователь не проходил обучение, то вы зарабатываете 2% со стоимости тарифа. Он получает скидку 15% на обучение по вашей ссылке
                </span>
            </div>

            <div style="padding-left: 20px; width: 50%;">
                <div style="margin-bottom: 40px">
                    <img alt="search" src="@/assets/images/pages/team/icons/student.svg" />
                </div>
                <span>
                    Если пользователь уже прошел обучение, то вы зарабатываете 10 внутренних монет за его добавление в команду
                </span>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-around" style="color:#9CA2B1; padding: 10px 0; border: 2px solid #EFF1F4; border-radius: 50px; margin-bottom: 40px" >
        <span>1</span>
        <feather-icon icon="ChevronRightIcon" size="16" />
        <span>2</span>
    </div>

     <div class="d-flex" style="margin-bottom: 20px">
        <div style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); order: 0; flex-grow: 0; border-radius: 24px; padding: 20px; margin-right: 20px">
            <div style="margin-bottom: 40px">
                <img alt="search" src="@/assets/images/pages/team/icons/developer.svg" />
            </div>
            <span>
                Пользователь попадает в вашу команду, вы можете видеть его статистику по заявкам, статистике, доходу. Участникам команды можно ставить план по заявкам или доходу в день и следить за его выполнением
            </span>
        </div>

        <div style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); order: 0; flex-grow: 0; border-radius: 24px; padding: 20px;">
            <div style="margin-bottom: 40px">
                <img alt="search" src="@/assets/images/pages/team/icons/money.svg" />
            </div>
            <span>
                Вы зарабатываете процент с прибыли ваших подопечных, процент зависит от количества людей в команде и их заработка
            </span>
        </div>
    </div>

    <div class="d-flex justify-content-around" style="color:#9CA2B1; padding: 10px 0; border: 2px solid #EFF1F4; border-radius: 50px;" >
        <span>3</span>
        <feather-icon icon="ChevronRightIcon" size="16" />
        <span>4</span>
    </div>

  </b-card>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
  components: { FeatherIcon },

}
</script>

<style>

</style>